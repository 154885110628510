<template>
  <div class="navigate">
    <div
      class="item"
      v-for="(item, index) in data"
      :key="item.uuid"
      @click="goto(item.uuid, index)"
      >
      <span>{{ item.name }}
        {{ index == data.length - 1 ? "" : ">" }}
<!--        <img src="../assets/images/xiala.png" v-if="barData.length>0 &&   index == data.length - 1" @click.stop="onpull()">-->
      </span>
<!--      <ul v-show="bar && index == data.length - 1" >
        <li v-for="(bar,barindex) in barData" :key="barindex" @click.stop="chooseChannel(bar.uuid)" >{{bar.name}}</li>
      </ul>-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navigate: Object,
    barData: {
      type:Array,
      default: ()=>[]
    },
    width: {
      type: String,
      default: '100px'
    },
  },
  data() {
    return {
      data: [],
      bar:false,
    };
  },
  watch: {
    '$route' (to, from) {
      this.$router.go(0);
    },
  },
  computed:{
  },
  created() {
    this.transferNavigate();
  },
  methods: {
    transferNavigate(navigate) {
      let nav = this.navigate.navigate;
      this.data = nav.split(" > ").map((item) => {
        const subStr = item.substring(1, item.length - 1);
        const splitStr = subStr.split(",");
        return {
          uuid: splitStr[1],
          name: splitStr[0],
        };
      });
    },
    goto(uuid, index) {
      if (!uuid) {
        this.$router.push({
          name: "index",
        });
        return;
      } else if (this.data.length - 1 === index) {
        location.reload();
        return;
      } else {
        this.$router.push({
          name: 'list',
          query: {
            sortId: uuid,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.navigate{
  display: flex;
 }
.navigate .item {
  color: #B3B3B3;
  cursor: pointer;
  font-size: 14px;
  padding-right: 5px;
}
.navigate .item img{
  vertical-align: middle;
}
.navigate .item:last-child {
  color: white;
}
.item ul{
  width: 148px;
  background: #FFFFFF;
  border-radius: 2px;
  padding: 20px 20px 0px 20px;
  box-sizing: border-box;
  z-index: 9;
  position: relative;
  margin-top: 5px;
}
.item ul li{
  padding-bottom: 20px;
  color: #333333;
  font-size: 14px;
}
.item ul li:hover{
   color:#FF5252
}
.item ul .color{
  color:#FF5252
}
</style>
