import request from '@/utils/request'

/**
 * 我的收藏
 * @param {pageNo,pageSize,type,uuid} params 
 * type: 0 条目收藏 1 条目内文收藏 2 笔记
 */
export function getUserCollectList(params) {
  return request({
    url: '/collect/list',
    method: 'get',
    params: params,
  })
}

/**
 * 浏览记录
 * @param {pageNo,pageSize} params 
 */
 export function getUserHistoryList(params) {
  return request({
    url: '/history/list',
    method: 'get',
    params: params,
  })
}

/**
 * 收藏与删除收藏
 * @param {string} uuid Item的uuid
 * @param {type,ids,del,txt,note} params 
 * @param {number} type : 0 条目收藏 1 条目内文收藏 2 笔记
 * @param {string} ids  : 最外层id，多个以英文逗号分隔(1,2,3)
 * 收藏/笔记删除：
 * 1.uuid=uuid&del=1 根据uuid与type删除，主要用于 详情页删除条目收藏
 * 2.uuid=del&ids 根据UserCollect的id删除 主要用于 条目内文收藏/笔记 个人中心收藏
 */
 export function collectItem(uuid,params) {
  return request({
    url: '/collect/'+uuid,
    method: 'get',
    params: params,
  })
}

// 用户头像上传
export function uploadAvatar(data) {
  return request({
    url: '/uploadAvatar',
    method: 'post',
    data: data
  })
}

// 更新用户信息
export function updateUserInfo(data) {
  return request({
    url: '/updateUserInfo',
    method: 'post',
    data: data
  })
}

// 更新密码
export function updatePassword(data) {
  return request({
    url: '/updatePassword',
    method: 'post',
    data: data
  })
}

